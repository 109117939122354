import { Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, Spacer, Text } from "@chakra-ui/react";
import { XboxUsersContext } from "lib/coplay/contexts/XboxUsersProvider";
import { useContext, useMemo, useState, useCallback } from "react";
import { XboxDashboardControls, XboxDashboardStats } from "./components/XboxDashboardControls";
import { XboxDashboardHeader } from "./components/XboxDashboardHeader";
import { SortOption, SortOptionsDropdown } from "./components/SortOptionsDropdown";
import { ExportButton } from "./components/ExportButton";
import { EmptyXboxUserFilters, FilterDropdown, XboxUserFilters } from "./components/FilterDropdown";
import { SelectAllCheckbox } from "./components/SelectAllCheckbox";
import { SortButton } from "./components/SortButton";
import { XboxSearchBar } from "./components/XboxSearchBar";
import { applySearch, applyFilters, sortUsers } from "lib/coplay/utils/XboxDashboardUtils";
import { XboxActionsDropdown } from "./components/actions/XboxActionsDropdown";
import { XboxUser } from "lib/coplay/types/BackendTypes";
import { DeviceDetailsModal } from "lib/coplay/components/xbox/device-details-modal/DeviceDetailsModal";
import { createConsoleCrumb } from "lib/coplay/utils/utils";
import { XboxAccountDrawer } from "./components/account-drawer/XboxAccountDrawer";
import { XboxStatusModal } from "./components/user/user-status/modal/XboxStatusModal";
import ExperimentalList from "../kitchen-sink/experimental-list";
import { RefreshDashboardButton } from "./components/RefreshDashboardButton";


export function useSorting() {
    const [sortOption, setSortOption] = useState(SortOption.Email);
    const [isDescending, setIsDescending] = useState(false);

    return {
        sortOption,
        setSortOption,
        isDescending,
        setIsDescending
    };
}

export function XboxDashboard() {
    const { xboxUsers, selectedUsers, addSelectedUser, removeSelectedUser, addMultipleSelectedUsers } = useContext(XboxUsersContext);
    const { sortOption, setSortOption, isDescending, setIsDescending } = useSorting();
    const [filters, setFilters] = useState<XboxUserFilters>(EmptyXboxUserFilters);
    const [search, setSearch] = useState('');
    const [xboxDeviceDetails, setXboxDeviceDetails] = useState<XboxUser | null>(null);
    const [xboxAccountDrawer, setXboxAccountDrawer] = useState<XboxUser | null>(null);
    const [xboxUserStatusModal, setXboxUserStatusModal] = useState<XboxUser | null>(null);
    const [lastSelectedUser, setLastSelectedUser] = useState<XboxUser | null>(null);

    // Memoize the displayed users
    const displayedUsers = useMemo(() => {
        let users = xboxUsers.toArray();
        users = applySearch(users, search);
        users = applyFilters(users, filters);
        users = sortUsers(users, sortOption, isDescending);

        return users;
    }, [xboxUsers, search, filters, sortOption, isDescending]);

    const handleUserSelection = useCallback((user: XboxUser, shiftKey: boolean) => {
        if (!shiftKey || !lastSelectedUser) {
            if (selectedUsers.has(user)) {
                removeSelectedUser(user);
            } else {
                addSelectedUser(user);
                setLastSelectedUser(user);
            }
            return;
        }

        const lastSelectedIndex = displayedUsers.findIndex(u => u.emailAddress === lastSelectedUser.emailAddress);
        const currentIndex = displayedUsers.findIndex(u => u.emailAddress === user.emailAddress);

        if (lastSelectedIndex === -1 || currentIndex === -1) {
            return;
        }

        const start = Math.min(lastSelectedIndex, currentIndex);
        const end = Math.max(lastSelectedIndex, currentIndex);

        addMultipleSelectedUsers(displayedUsers.slice(start, end + 1));
    }, [lastSelectedUser, selectedUsers, displayedUsers, removeSelectedUser, addSelectedUser, setLastSelectedUser, addMultipleSelectedUsers]);


    return (
        <Flex flexDirection='column' height='100%' id='xbox-dashboard'>
            <XboxDashboardControls>
                <XboxDashboardStats
                    totalUsers={xboxUsers.size()}
                    selectedUsers={selectedUsers.size()}
                    displayedUsers={displayedUsers.length}
                />
                <XboxActionsDropdown selectedUsers={selectedUsers} />
            </XboxDashboardControls>
            <Flex flexDirection='column' height='100%'>
                <XboxDashboardHeader>
                    <SelectAllCheckbox displayedUsers={displayedUsers} />
                    <Text fontSize='xl' fontWeight='bold'>Your Xbox Users</Text>
                    <RefreshDashboardButton />
                    <ExportButton />

                    <XboxSearchBar
                        searchText={search}
                        onSearchChange={(e) => setSearch(e.target.value)}
                    />
                    <Spacer />
                    <Flex gap='2' overflowY='scroll'>
                        <FilterDropdown
                            filters={filters}
                            setFilters={setFilters}
                            users={xboxUsers}
                        />
                        <SortOptionsDropdown
                            sortOption={sortOption}
                            onSortChange={setSortOption}
                        />
                        <SortButton
                            isDescending={isDescending}
                            setIsDescending={setIsDescending}
                        />
                    </Flex>
                </XboxDashboardHeader>
                {/* <Divider colorScheme='brand' my={1} border={`1px solid ${brandColor}`} /> */}
                <Flex flexDirection='column' height='100%' overflowY='scroll' id='xbox-dashboard-content'>
                    {xboxDeviceDetails &&
                        <DeviceDetailsModal
                            emailAddress={xboxDeviceDetails.emailAddress}
                            onClose={() => { setXboxDeviceDetails(null) }}
                            crumb={createConsoleCrumb(xboxDeviceDetails)}
                        />
                    }

                    {/* Xbox Account Drawer */}
                    <Drawer
                        isOpen={xboxAccountDrawer !== null}
                        placement='right'
                        onClose={() => { setXboxAccountDrawer(null) }}
                        size='xl'
                    >
                        <DrawerOverlay />
                        <DrawerContent p='6'>
                            <DrawerCloseButton />
                            <XboxAccountDrawer
                                updatedAt={xboxAccountDrawer?.updatedAt}
                                selectedXboxEmail={xboxAccountDrawer?.emailAddress}
                                onClose={() => { setXboxAccountDrawer(null) }}
                            />
                        </DrawerContent>
                    </Drawer >

                    {/*Error Details Modal */}
                    {xboxUserStatusModal &&
                        <XboxStatusModal
                            user={xboxUserStatusModal}
                            onClose={() => { setXboxUserStatusModal(null) }}
                        />
                    }

                    <ExperimentalList
                        users={displayedUsers}
                        selectedUsers={selectedUsers}
                        onSelect={handleUserSelection}
                        onDeselect={removeSelectedUser}
                        onDeviceDetails={setXboxDeviceDetails}
                        onAccountDetails={setXboxAccountDrawer}
                        onStatusDetails={setXboxUserStatusModal}
                    />

                    {/* <ul>
                        {displayedUsers.map((user) => (
                            <XboxUserCard
                                user={user}
                                isSelected={selectedUsers.has(user)}
                                onSelect={() => addSelectedUser(user)}
                                onDeselect={() => removeSelectedUser(user)}
                                onDeviceDetails={() => setXboxDeviceDetails(user)}
                                onAccountDetails={() => setXboxAccountDrawer(user)}
                                onStatusDetails={() => setXboxUserStatusModal(user)}
                            />
                        ))}
                    </ul> */}
                </Flex>
            </Flex>
        </Flex>
    )
}