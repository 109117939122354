import { useState } from "react";
import { Flex, VStack, Text, IconButton, Spacer, Button } from '@chakra-ui/react';
import { MdArrowBack } from 'react-icons/md';
import { MdDownload } from 'react-icons/md';
import { XMProfile } from "lib/coplay/types/BackendTypes";
import EditProfileButton from "./components/EditProfileButton";
import ProfileDetailsPage from "./components/ProfileDetailsPage";

export function ProfileDetails(props: { profile?: XMProfile, onBack: () => void, onDelete: (profile: XMProfile) => void }) {
    const [getEditing, setEditing] = useState<boolean>();

    const handleExport = () => {
        if (!props.profile) return;

        const fileName = `${props.profile.name}-${props.profile.id}.json`;
        const jsonStr = JSON.stringify(props.profile, null, 2);
        const blob = new Blob([jsonStr], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    if (!props.profile)
        return (
            <VStack spacing={4} align="stretch">
                <IconButton
                    aria-label="Go back"
                    icon={<MdArrowBack />}
                    onClick={() => props.onBack()}
                    mb={4} />
                <Text>No profile selected</Text>
            </VStack>
        );

    return (
        <VStack spacing={4} align="stretch">
            <Flex justifyContent='left' w='100%'>
                <IconButton
                    aria-label="Go back"
                    icon={<MdArrowBack />}
                    onClick={() => props.onBack()}
                    alignSelf='left' />
                <Spacer />
                <Button
                    style={{ maxWidth: "87px" }}
                    colorScheme='red'
                    variant="ghost"
                    onClick={() => { props.onDelete(props.profile) }}
                >Delete</Button>
                <EditProfileButton
                    getEditing={getEditing}
                    setEditing={setEditing}
                />
                <IconButton
                    aria-label="Export Profile"
                    icon={<MdDownload />}
                    onClick={handleExport}
                    colorScheme="blue"
                    variant="ghost"
                />
            </Flex>
            <ProfileDetailsPage
                editing={getEditing}
                profile={props.profile}
                onCreated={() => setEditing(false)}
            />
        </VStack>
    );
}
