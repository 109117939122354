import { Box, Input, Button, Text, TabPanel, Spacer } from "@chakra-ui/react";
import { useState } from "react";
import { XboxPerson, OauthActions } from "lib/coplay/types/BackendTypes";
import { handleXboxFriending } from "lib/backend/api";
import { PersonItem, ZeroState } from "lib/coplay/views/xbox/components/account-drawer/components/XboxAccountFriends";

interface FriendsPanelProps {
    friends?: XboxPerson[];
    emailAddress: string;
    searchQuery: string;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function FriendsPanel({ friends, emailAddress, searchQuery, onSearchChange }: FriendsPanelProps) {
    const [selectedFriends, setSelectedFriends] = useState<string[]>([]);
    const [isRemoving, setIsRemoving] = useState(false);

    const handleFriendAction = async (action: OauthActions.RemoveFriends) => {
        setIsRemoving(true);
        try {
            await handleXboxFriending([emailAddress], selectedFriends, action);
            setSelectedFriends([]); // Reset selection after successful action
        } finally {
            setIsRemoving(false);
        }
    };

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            const allFriendXuids = friends?.map(p => p.xuid) || [];
            setSelectedFriends(allFriendXuids);
        } else {
            setSelectedFriends([]);
        }
    };

    const filterItems = (items: XboxPerson[] = []) => {
        return items.filter(item =>
            item.gamertag.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    return (
        <TabPanel p='0'>
            <Input
                placeholder="Search friends..."
                mb='2'
                value={searchQuery}
                onChange={onSearchChange}
            />
            <Box mb='4' display='flex' gap='2' justifyContent='space-between' alignItems='center'>
                <Box display='flex' w='100%' gap='2'>
                    <Button
                        colorScheme='red'
                        size='sm'
                        isDisabled={selectedFriends.length === 0}
                        isLoading={isRemoving}
                        onClick={() => handleFriendAction(OauthActions.RemoveFriends)}
                    >
                        Remove Selected
                    </Button>
                    <Spacer />
                    <Box display='flex' alignItems='center' gap='2' pr='4'>
                        <Text fontSize='sm'>Select All</Text>
                        <input
                            type="checkbox"
                            checked={selectedFriends.length === friends?.length && friends?.length > 0}
                            onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                    </Box>
                </Box>
            </Box>
            <Box p='4' overflowY='scroll' maxHeight='400px' bg='gray.100'>
                {!friends?.length ? (
                    <ZeroState />
                ) : (
                    filterItems(friends)
                        .sort((a, b) => a.gamertag.localeCompare(b.gamertag))
                        .map((friend) => (
                            <PersonItem
                                key={friend.xuid}
                                gamertag={friend.gamertag}
                                xuid={friend.xuid}
                                isSelectable={true}
                                isSelected={selectedFriends.includes(friend.xuid)}
                                onToggleSelect={(xuid) => {
                                    setSelectedFriends(prev =>
                                        prev.includes(xuid)
                                            ? prev.filter(id => id !== xuid)
                                            : [...prev, xuid]
                                    );
                                }}
                            />
                        ))
                )}
            </Box>
        </TabPanel>
    );
} 