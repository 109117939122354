import { useState } from "react";
import { Flex, Heading, useColorModeValue, Text, Button } from "@chakra-ui/react";
import { IoGameController } from "react-icons/io5";
import Footer from 'lib/horizon/components/footer/FooterAuth';


export function XboxCodeView() {
    const textColorSecondary = "gray.400";
    const textColorError = "red.400";
    const textColor = useColorModeValue("navy.700", "white");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const [copySuccess, setCopySuccess] = useState("");
    // Have to use this janky method to get the code from the URL because of the hashrouter
    const code = window.location.toString().split('?code=')[1];

    const copyToClipBoard = async (copyMe: string) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied code to clipboard');
        }
        catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };

    const renderLoginCode: JSX.Element =
        <>
            <Heading color={textColor} fontSize='36px' px='10px' textAlign='center'>
                Hey! We got your login code!
            </Heading>
            <Text
                mb='26px'
                ms='4px'
                color={textColorSecondary}
                fontWeight='400'
                fontSize='md'
                textAlign='center'>
                Use this code to add your Xbox Live account to CoPlay XM!
            </Text>
            <Text
                mb='16px'
                ms='4px'
                color={textColorBrand}
                fontWeight='900'
                fontSize='xl'
                textAlign='center'>
                {code}
            </Text>
            <Text
                color={textColorBrand}
                mb='16px'
                fontWeight='400'
                fontSize='ms'
                textAlign='center'>
                {copySuccess}
            </Text>
            <Button variant='brand' w='300px' onClick={(e) => copyToClipBoard(code)}> Copy Code </Button>
        </>

    const renderError: JSX.Element =
        <>
            <Heading color={textColor} fontSize='36px' px='10px' textAlign='center'>
                Uh oh! We couldn't get your login code!
            </Heading>
            <Text
                mb='26px'
                ms='4px'
                color={textColorError}
                fontWeight='400'
                fontSize='md'
                textAlign='center'>
                Please try again later or contact support.
            </Text>
        </>


    return (
        <>
            <Flex
                id='codeFlex'
                maxW={{ base: "100%", md: "max-content" }}
                w='98vw'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='95vh'
                alignSelf='center'
                alignItems='center'
                justifyContent='center'
                px={{ base: "25px", md: "0px" }}
                flexDirection='column' >
                <Flex w='98vw' h='95%' flexDirection='column' alignItems='center' justifyContent='center'>
                    <IoGameController size='120px' />
                    {code ? renderLoginCode : renderError}
                </Flex>
                <Footer />
            </Flex>
        </>
    );
}

export default XboxCodeView;