import { Box, Input, TabPanel } from "@chakra-ui/react";
import { XboxPerson } from "lib/coplay/types/BackendTypes";
import { PersonItem, ZeroState } from "lib/coplay/views/xbox/components/account-drawer/components/XboxAccountFriends";


interface FollowersPanelProps {
    followers?: XboxPerson[];
    searchQuery: string;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function FollowersPanel({ followers, searchQuery, onSearchChange }: FollowersPanelProps) {
    const filterItems = (items: XboxPerson[] = []) => {
        return items.filter(item =>
            item.gamertag.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    return (
        <TabPanel p='0'>
            <Input
                placeholder="Search followers..."
                mb='2'
                value={searchQuery}
                onChange={onSearchChange}
            />
            <Box p='4' overflowY='scroll' maxHeight='400px' bg='gray.100'>
                {!followers?.length ? (
                    <ZeroState type="followers" />
                ) : (
                    filterItems(followers)
                        .map((person) => (
                            <PersonItem
                                key={person.xuid}
                                gamertag={person.gamertag}
                            />
                        ))
                )}
            </Box>
        </TabPanel>
    );
} 