import { Box, Input, Button, Text, TabPanel } from "@chakra-ui/react";
import { useState } from "react";
import { XboxPerson, OauthActions } from "lib/coplay/types/BackendTypes";
import { handleXboxFriending } from "lib/backend/api";
import { PersonItem, ZeroState } from "lib/coplay/views/xbox/components/account-drawer/components/XboxAccountFriends";


interface RequestsPanelProps {
    requests?: XboxPerson[];
    emailAddress: string;
    searchQuery: string;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function RequestsPanel({ requests, emailAddress, searchQuery, onSearchChange }: RequestsPanelProps) {
    const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<'accept' | 'deny' | null>(null);

    const handleRequestAction = async (action: OauthActions.AddFriends | OauthActions.RemoveFriends) => {
        const actionType = action === OauthActions.AddFriends ? 'accept' : 'deny';
        setIsLoading(actionType);
        try {
            await handleXboxFriending([emailAddress], selectedRequests, action);
            setSelectedRequests([]); // Reset selection after action
        } finally {
            setIsLoading(null);
        }
    };

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            const allRequestXuids = requests?.map(p => p.xuid) || [];
            setSelectedRequests(allRequestXuids);
        } else {
            setSelectedRequests([]);
        }
    };

    const filterItems = (items: XboxPerson[] = []) => {
        return items.filter(item =>
            item.gamertag.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    return (
        <TabPanel p='0'>
            <Text fontSize='md' mb='2'>
                Friend requests that you have sent other people will appear here.
            </Text>
            <Input
                placeholder="Search requests..."
                mb='2'
                value={searchQuery}
                onChange={onSearchChange}
            />
            <Box mb='4' display='flex' gap='2' justifyContent='space-between' alignItems='center'>
                <Box display='flex' gap='2'>
                    <Button
                        colorScheme='red'
                        size='sm'
                        isDisabled={selectedRequests.length === 0 || isLoading !== null}
                        isLoading={isLoading === 'deny'}
                        onClick={() => handleRequestAction(OauthActions.RemoveFriends)}
                    >
                        Cancel Requests
                    </Button>
                </Box>
                <Box display='flex' alignItems='center' gap='2' pr='4'>
                    <Text fontSize='sm'>Select All</Text>
                    <input
                        type="checkbox"
                        checked={selectedRequests.length === requests?.length && requests?.length > 0}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                </Box>
            </Box>
            <Box p='4' overflowY='scroll' maxHeight='400px' bg='gray.100'>
                {!requests?.length ? (
                    <ZeroState type="invites" />
                ) : (
                    filterItems(requests)
                        .map((person) => (
                            <PersonItem
                                key={person.xuid}
                                gamertag={person.gamertag}
                                xuid={person.xuid}
                                isSelectable={true}
                                isSelected={selectedRequests.includes(person.xuid)}
                                onToggleSelect={(xuid) => {
                                    setSelectedRequests(prev =>
                                        prev.includes(xuid)
                                            ? prev.filter(id => id !== xuid)
                                            : [...prev, xuid]
                                    );
                                }}
                            />
                        ))
                )}
            </Box>
        </TabPanel>
    );
} 