import { Box, Text, Button, Tabs, TabList, TabPanels, Tab } from "@chakra-ui/react";
import { handleXboxFriending } from "lib/backend/api";
import { OauthActions, XboxFriends } from "lib/coplay/types/BackendTypes";
import { useState } from "react";
import { FriendsPanel } from './panels/FriendsPanel';
import { InvitesPanel } from './panels/InvitesPanel';
import { RequestsPanel } from "./panels/RequestsPanel";
import { FollowersPanel } from "./panels/FollowersPanel";
import { FollowingPanel } from "./panels/FollowingPanel";

// TODO: Add Delete friend button, clean up the friend renderer
export function XboxAccountFriends(props: { emailAddress: string, friends?: XboxFriends }) {
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <Box mb='4'>
            <Text as='h3' fontSize='lg' fontWeight='bold'>Friends</Text>
            <Text fontWeight='500' mb='4'>Manage your Xbox friends, followers, and requests.</Text>

            <Tabs variant='line' colorScheme='blue'>
                <TabList mb='4'>
                    <Tab>Friends {props.friends?.people?.length ? `(${props.friends.people.length})` : ''}</Tab>
                    <Tab>Friend Requests {props.friends?.friendRequests?.length ? `(${props.friends.friendRequests.length})` : ''}</Tab>
                    <Tab>Friend Invites {props.friends?.friendInvites?.length ? `(${props.friends.friendInvites.length})` : ''}</Tab>
                    <Tab>Followers {props.friends?.followers?.people.length ? `(${props.friends.followers.people.length})` : ''}</Tab>
                    <Tab>Following {props.friends?.following?.people.length ? `(${props.friends.following.people.length})` : ''}</Tab>
                </TabList>

                <TabPanels>
                    <FriendsPanel
                        friends={props.friends?.people}
                        emailAddress={props.emailAddress}
                        searchQuery={searchQuery}
                        onSearchChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <RequestsPanel
                        requests={props.friends?.friendRequests}
                        emailAddress={props.emailAddress}
                        searchQuery={searchQuery}
                        onSearchChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <InvitesPanel
                        invites={props.friends?.friendInvites}
                        emailAddress={props.emailAddress}
                        searchQuery={searchQuery}
                        onSearchChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <FollowersPanel
                        followers={props.friends?.followers?.people}
                        searchQuery={searchQuery}
                        onSearchChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <FollowingPanel
                        following={props.friends?.following?.people}
                        searchQuery={searchQuery}
                        onSearchChange={(e) => setSearchQuery(e.target.value)}
                    />
                </TabPanels>
            </Tabs>

            {props.friends?.statusMessage?.split('\n').map((line, index) => (
                <Text key={index} fontSize='xs' color="red.500" fontWeight='500'>{line}</Text>
            ))}
        </Box>
    )
}

export function FriendItem(props: { gamertag: string, xuid: string, emailAddress: string }) {
    const [isLoading, setIsLoading] = useState(false);
    const handleDelete = (xuid: string) => {
        setIsLoading(true);
        handleXboxFriending([props.emailAddress], [xuid], OauthActions.RemoveFriends).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <Box key={props.xuid} display='flex' alignItems='center' justifyContent='space-between' bg='white' borderRadius='md' mb='2' boxShadow='sm'>
            <Text p='1'>{props.gamertag}</Text>
            <Button
                colorScheme='red'
                variant='ghost'
                size='xs'
                p='2'
                isLoading={isLoading}
                onClick={() => handleDelete(props.xuid)}>
                Remove
            </Button>
        </Box>
    )
}

export function PersonItem(props: {
    gamertag: string,
    xuid?: string,
    isSelectable?: boolean,
    isSelected?: boolean,
    onToggleSelect?: (xuid: string) => void
}) {
    return (
        <Box display='flex' alignItems='center' justifyContent='space-between' bg='white' borderRadius='md' mb='2' boxShadow='sm'>
            <Text p='2'>{props.gamertag}</Text>
            {props.isSelectable && props.xuid && (
                <Box p='2'>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => props.onToggleSelect?.(props.xuid!)}
                    />
                </Box>
            )}
        </Box>
    )
}

export function ZeroState({ type = "friends" }: { type?: string }) {
    const messages = {
        friends: "This account has no friends. Add some friends to see them here!",
        followers: "No followers yet.",
        following: "Not following anyone yet.",
        requests: "You have not received any friend requests.",
        invites: "You have not sent any friend requests."
    };

    return (
        <Text m='8' textAlign='center' color='gray.500' fontSize='md' fontStyle='italic'>
            {messages[type as keyof typeof messages]}
        </Text>
    )
}

